import React from 'react';
import './App.css';

function Navbar() {
    return (
        <header>
            <nav className="navbar">
                <div className="container1">
                    
                    <span className="company-name">London Logistics Services CO.</span>
                </div>

            </nav>
        </header>
    );
}

export default Navbar;
