import React from 'react';
import Navbar from './Navbar';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './App.css';

function App() {
    // Sample data for demonstration purposes
    const images = [
        { id: 0, name: 'London Logistics', url: '32.jpeg' },
        { id: 1, name: 'Livogen', url: '1.jpeg' },
        { id: 2, name: 'SUREKIT', url: '2.jpeg' },
        { id: 3, name: 'LEVOGIN', url: '3.jpeg' },
        { id: 4, name: 'NEUROSELL', url: '4.jpeg' },
        { id: 5, name: 'OMEGIN-20', url: '5.jpeg' },
        { id: 6, name: 'Soride-IV', url: '6.jpeg' },
        { id: 7, name: 'AMFLUX', url: '7.jpeg' },
        { id: 8, name: 'EBS Tade', url: '8.jpeg' },
        { id: 9, name: 'CEFTIGIN', url: '9.jpeg' },
        { id: 10, name: 'OMEGIN', url: '10.jpeg' },
        { id: 11, name: 'CEFODON', url: '11.jpeg' },
        { id: 12, name: 'CIMIGIN', url: '12.jpeg' },
        { id: 13, name: 'CEFTIDON', url: '13.jpeg' },
        { id: 14, name: 'OMEGIN', url: '14.jpeg' },
        { id: 15, name: 'ACIAMOX_INJ', url: '15.jpeg' },
        { id: 16, name: 'Dalvit +', url: '16.jpeg' },
        { id: 17, name: 'Oxytocia', url: '17.jpeg' },
        { id: 18, name: 'Dalvit', url: '18.jpeg' },
        { id: 19, name: 'PARAGIN', url: '19.jpeg' },
        { id: 20, name: 'Dalvit', url: '20.jpeg' },
        { id: 21, name: 'DEPO PROGIN', url: '21.jpeg' },
        { id: 22, name: 'Methercia', url: '22.jpeg' },
        { id: 23, name: 'Ondagin', url: '23.jpeg' },
        { id: 24, name: 'Omegin', url: '24.jpeg' },
        { id: 25, name: 'Diclogin', url: '25.jpeg' },
        { id: 26, name: 'Dalvit', url: '26.jpeg' },
        { id: 27, name: 'Merodon', url: '27.jpeg' },
        { id: 28, name: 'Vitamin D3 K2', url: '28.jpeg' },
        { id: 29, name: 'Vitamin K2', url: '29.jpeg' },
        { id: 30, name: 'DOMIGIN', url: '30.jpeg' },
        { id: 31, name: 'AMOXIGIN', url: '31.jpeg' },
    ];

    return (
        <div className="App">
            <Navbar />
            <main className="main-section">
                <div className="container">
                    <Carousel showArrows={true} interval={5000}>
                        {images.map((image) => (
                            <div key={image.id}>
                                <img src={image.url} alt={image.name} />
                                <p className="legend">{image.name}</p>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </main>
            <footer className="footer-section">
                <div className="footer-container">
                    <h2 className="company-name">London Logistics Services CO.</h2>
                    <div className="company-info">
                        <div>
                            <p>Phone: +962777176666</p>
                            <p>Address: AMMAN JORDAN</p>
                            <p>Email: medicalslondon@gmail.com</p>
                        </div>
                        <img className='logo-img' src='32.jpeg' alt='Company Logo' />                    </div>
                </div>
            </footer>
        </div>
    );
}

export default App;
